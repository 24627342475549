@charset "utf-8";
/* @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css'); */

/*******************************************************************/
/*******************************************************************/

.govent-push{
  position: relative;
  z-index: 9998;
}

/* 알림 아이콘 */
.govent-push__noti-btn {
  width: 48px ;
  height: 48px;
  border-radius: 18px;
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 3px, rgb(0 0 0 / 10%) 0px 6px 15px, rgb(255 255 255 / 20%) 0px 0px 0px 1px inset;
  -webkit-box-shadow: rgb(0 0 0 / 5%) 0px 2px 3px, rgb(0 0 0 / 10%) 0px 6px 15px, rgb(255 255 255 / 20%) 0px 0px 0px 1px inset;

  position: fixed;
  top: 14px;
  right: 14px;

  transition: all .2s ease-in;
  -webkit-background: linear-gradient(
    to bottom,
    rgba(0, 143, 105, 0.8) 0%,
    rgba(13, 119, 91, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 143, 105, 0.8) 0%,
    rgba(13, 119, 91, 0.8) 100%
  );
  cursor: pointer;
  z-index: 999999 !important;
}

.govent-push__noti-btn:hover {
  transition: all .2s ease-in;
  -webkit-background: linear-gradient(
    to bottom,
    rgba(0, 143, 105, 1) 0%,
    rgba(13, 119, 91, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 143, 105, 1) 0%,
    rgba(13, 119, 91, 1) 100%
  );
}
.govent-push__icon{
  z-index: 1000;
  width:48px;
  height: 48px;
  display: flex;
  justify-content:center;
  align-items: center;
}
.govent-push__icon ion-icon,
.govent-push__icon--ion{
  display: flex;
  justify-content:center;
  align-items: center;
  width: 30px;
  height:30px;
  color:#fff;
  /* font-size: 2.8rem; */
  text-shadow: 2px 2px 2px rgba(56, 56, 56, 0.7);
  --ionicon-stroke-width: 40px;
}

/* 새 메세지 표시 */
.govent-push__new-mark {
  position: absolute;
  top: 0;
  right: -0.4rem;
  width: 1.6rem;
  height: 1.6rem;
  background-color: #ff4545;
  border-radius: 8px;
}

/* 새 메세지 말풍선 */
.govent-push__new-message {
  position: fixed;
  top: 1.2rem;
  right: 8.8rem;
  max-width: 32rem;
  min-height: 5rem;
  background-color: #fff;
  padding: 1.2rem 1.6rem 1.6rem;
  border-radius: 0.8rem;
  -webkit-box-shadow: 2px 1.5px 10px 0 rgba(35, 24, 21, 0.6);
  box-shadow: 2px 1.5px 10px 0 rgba(35, 24, 21, 0.6);
  border: 2px solid rgba(0, 145, 106, 0.9);
  z-index: 1000;
}

.govent-push__new-message:after {
  content: "";
  position: absolute;
  top: 0.8rem;
  right: -1.6rem;
  display: block;
  border-width: 8px;
  border-style: solid;
  border-color: rgba(0, 145, 106, 0.9) transparent transparent rgba(0, 145, 106, 0.9);
}

/* 새 메세지 - 닫기 버튼 */
.govent-push__new-close {
  position: absolute;
  top: 0.8rem;
  right: 1.2rem;
  width: 2rem;
  height: 2rem;
}
.govent-push__new-close:before,
.govent-push__new-close:after {
  content: "";
  width: 18px;
  height: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 0.4rem;
  background-color: #e5e5e5;
  transition: all .2s ease-in;
}
.govent-push__new-close:hover:before,
.govent-push__new-close:hover:after {
  background-color: #d8d8d8;
}
.govent-push__new-close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.govent-push__new-close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

/* 새 메세지 - 이름 */
.govent-push__new-name {
  font-size: 1.6rem;
  font-weight: 500;
  padding-right: 2.4rem;
  margin-bottom: 0.8rem;
}

/* 새 메세지 - 일시 */
.govent-push__new-date {
  font-size: 1.2rem;
  font-weight: 500;
  padding-right: 2.4rem;
  margin-bottom: 0.8rem;
  color: #a5a5a5;
}

/* 새 메세지 - 대화내용 */
.govent-push__new-txt {
  font-size: 1.4rem;
}

.govent-push__modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100000001 !important;
}

/* 대화목록 */
.govent-push__message-wrap {
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 36rem;
  height: 64rem;
  border-radius: 4px;
  z-index: 100000002 !important;
}

/* 대화목록 헤더 */
.govent-push__message-header {
  height: 44px;
  background-color: rgba(1, 1, 1, 0.7);
  color: #fff;
  border-radius: 2px 2px 0 0;
  padding:1.2rem 1.6rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 대화목록 - 닫기 버튼 */
.govent-push__message-close {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: relative;
}
.govent-push__message-close:before,
.govent-push__message-close:after {
  content: "";
  width: 18px;
  height: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 0.4rem;
  background-color: rgba(255,255,255,0.8);
  transition: all .2s ease-in;
}
.govent-push__message-close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.govent-push__message-close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.govent-push__message-close:hover:before,
.govent-push__message-close:hover:after{
  background-color:#fff;
}

/* 대화목록 - 돌아가기 버튼 */
.govent-push__message-prev {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  font-weight: 500;
  color: rgba(255,255,255,0.8);
  transition: all .2s ease-in;
}
.govent-push__message-prev:hover {
  color: #fff;
}

/* 대화목록 - 리스트 이름 */
.govent-push__message-listname {
  max-width: calc(100% - 12rem);
  font-size: 1.6rem;
  position: absolute;
  padding-top: .2em;
  left: 5rem;
}

/* 대화목록 - 리스트 */
.govent-push__message-con {
  height: calc(100% - 4.4rem);
  border-radius: 4px 4px 4px 4px;
  -webkit-box-shadow: inset 0.1rem 0.1rem 0.8rem rgba(0, 0, 0, 0.1);
  box-shadow: inset 0.1rem 0.1rem 0.8rem rgba(0, 0, 0, 0.1);
}

/* 대화목록 - 리스트 스크롤 */
.govent-push__message-ul {
  overflow-y: auto;
  height: calc(100% - 0.6rem);
  padding: 0 1.6rem;
  -webkit-box-shadow: inset 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  box-shadow: inset 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}
.govent-push__message-ul::-webkit-scrollbar {
  background-color: transparent;
  border: 0.5em solid transparent;
  width: 1em;
}
.govent-push__message-ul::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 10px;
}
.govent-push__message-ul::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

/* 대화목록 - 검색 */
.govent-push__search {
  height: 5.6rem;
  padding: 1.2rem 2.4rem;
}
.govent-push__search-input {
  width: 100%;
  height: 3.2rem;
  background-color: #fff;
  border-bottom: 1px solid #a5a5a5;
  background-color: #efefef;
}
.govent-push__search-input:focus {
  border-bottom: 1px solid #00916A;
}
.govent-push__search-btn {
  position: absolute;
  right: 2.4rem;
  width: 3.2rem;
  height: 3.2rem;
}
.govent-push__search-btn:hover path {
  fill: #00916A !important;
}

/* 대화목록 - 아이템 */
.govent-push__message-li {
  position: relative;
  width: 100%;
  padding: 2.4rem 0;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.govent-push__message-li:last-child {
  border-bottom: none;
}

/* 대화목록 - 공지 */
.govent-push__message-li.notice > .govent-push__message-name{
  display: inline-block;
  width: 58%;
  font-size: 1.6rem;
  font-weight: 700;
  color: #00916A;
  margin-bottom: 1.6rem;
  position: relative;
  padding-left: 1.8rem;
}
.govent-push__message-li.notice > .govent-push__message-name::before{
  content: "";
  display: inline-block;
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  left:0;
  background-color: #00916A;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

/* 대화목록 - 이름 */
.govent-push__message-name {
  display: inline-block;
  width: 58%;
  font-size: 1.6rem;
  font-weight: 600;
  color: #383838;
  margin-bottom: 1.6rem;
}

/* 대화목록 - 일시 */
.govent-push__message-date {
  display: inline-block;
  width: 41%;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: right;
  margin-bottom: 1.6rem;
  color: #a5a5a5;
}

/* 대화목록 - 대화내용 */
.govent-push__message-txt {
  width: calc(100% - 3.2rem);
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color:#666;
}
.govent-push__message-txt:after {
  content: "";
  position: absolute;
  right: 0.4rem;
  bottom: 2.6rem;
  display: block;
  border-top: 0.6rem solid transparent;
  border-left: 2rem solid transparent;
  border-bottom: 0.6rem solid transparent;
  transition-duration: 0.2s;
}
.govent-push__message-li:hover .govent-push__message-txt:after {
  border-left: 0.6rem solid #383838;
}

/* log */
.govent-push__log {
  height: calc(100% - 4.4rem);
  padding: 2.4rem 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* 대화목록 - 리스트 스크롤 */
.govent-push__log::-webkit-scrollbar {
  background-color: transparent;
  border: 0.5em solid transparent;
  width: 1em;
}
.govent-push__log::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 10px;
}
.govent-push__log::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

/* 대화목록 - 메세지 */
.govent-push__log-item {
  position: relative;
  margin-bottom: 1rem;
}

/*  대화목록 - 메세지 - 유저이름 */
.govent-push__log-userName {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #383838;
}

/* 대화목록 - 메세지 - 메세지 내용 */
.govent-push__log-con p {
  display: inline-block;
  position: relative;
  max-width: 80%;
  background-color: #efefef;
  border-radius: 4px;
  padding: 1.2rem 2rem;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  line-height: 1.2;
  border: solid 1px #e5e5e5;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

/* 대화목록 - 메세지 - 보낸 시간 */
.govent-push__log-time {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 6rem;
  padding: 0 0 0 1.2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: left;
  color: #999;
  transform: translateX(100%);
}
.govent-push__log-con:last-child p {
  margin-bottom: 0;
}

/* me */
.govent-push__log-item--own{
  margin-bottom: 1rem;
}
.govent-push__log-item--own .govent-push__log-userName {
  text-align: right;
  color: #00916A;
  border: none;
}
.govent-push__log-item--own .govent-push__log-con {
  text-align: right;
}
.govent-push__log-item--own p {
  text-align: left;
  background-color: #00916A;
  color: #fff;
}
.govent-push__log-item--own .govent-push__log-userName {
  background-color: transparent;
}
.govent-push__log-item--own .govent-push__log-time {
  right: 100%;
  transform: translateX(0);
  padding: 0 1.2rem 0 0;
  text-align: right;
}

/* staff */
.govent-push__log-item--staff{
  margin-bottom: 1rem;
}
.govent-push__log-item--staff p {
  text-align: left;
  background-color: #fff;
  border: 1px solid #ffdcd7;
}
.govent-push__log-item--staff .govent-push__log-userName {
  padding-left: 2rem;
  color: #eb5a46;
  border: none;
}
.govent-push__log-item--staff .govent-push__log-userName:before {
  content: "";
  display: block;
  position: absolute;
  left: 2.4rem;
  width: 1.4rem;
  height: 1.4rem;
  background-color: #eb5a46;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.govent-push__log-item--own.govent-push__log-item--staff
  .govent-push__log-userName:before {
  display: none;
}

/* send(input) */
.govent-push__send {
  position: relative;
  width: 100%;
  height: 4.4rem;
  overflow: hidden;
  -webkit-box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
  border: solid 1px #e5e5e5;
}
.govent-push__send-input {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 4.4rem);
  height: 100%;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  color: #383838;
  border: none !important;
  margin-top: 0 !important;
}
.govent-push__send-input:focus{
  border: none !important;
}
.govent-push__send-input::placeholder {
  font-size: 1.4rem;
  margin: 0;
  border: 0;
  color: #ccc;
}
.govent-push__send-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 4.4rem;
  height: 4.4rem;
}
.govent-push__send-btn:hover path {
  fill: #00916A !important;
  transition: all .2s ease-in;
}

/***********************************************************************/
/************************ (Mobile) Media Quries ************************/
/***********************************************************************/

@media only screen and (max-width:769px){
  .govent-push__noti-btn {
    width: 56px;
    height: 56px;
    top: auto;
    left: 24px;
    bottom: 24px;
    bottom: calc(24px + env(safe-area-inset-bottom)) !important;
    bottom: calc(24px + constant(safe-area-inset-bottom)) !important;
    backdrop-filter: blur(30px);
    border-radius: 24px;
  }
  .govent-push__icon {
    width: 56px;
    height: 56px;
  }
  .govent-push__icon--ion,
  .govent-push__icon ion-icon{
    width: 36px;
    height: 36px;
  }
  .govent-push__new-message{
    top:auto;
    bottom:1.2rem;
    right: 7.8rem;
}
  .govent-push__new-message::after{
    top:auto;
    bottom:.8rem;
  }
}

@media only screen and (max-width:501px) {
  .govent-push__message-wrap{
    top:2rem;
    transform: translateX(-50%);
  }
    .govent-push__new-message {
      right: 7rem;
    }
          .govent-push__new-message::after {
            border-width: 6px;
            right: -1.2rem;
          }
}
@media only screen and (orientation:landscape) and (max-height: 501px) {
  .govent-push__message-wrap {
    width: 98% !important;
    height: 90% !important;
  }
}
